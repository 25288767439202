/* eslint-disable-next-line */

import axios from 'axios'
import { AuthService } from '../../../../services/auth'
import { useDispatch } from 'react-redux';
//import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const { loginService } = AuthService();

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export async function login(email: string, password: string, dispatch: any) {
  const response = await loginService(email, password, dispatch);
  
  return {
    data: {
      access_token: response.access_token,
      refresh_token: response.refresh_token
    }
  }
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return {
    data: {
      id: 132,
      username: "string",
      password: "string",
      email: "assistouch@admin.com",
      first_name: "Assistouch",
      last_name: "Demo"
    }
  }
}


// //import {AuthModel, UserModel} from './_models'

// import axios from "axios"

// const API_URL = process.env.REACT_APP_API_URL

// export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
// export const LOGIN_URL = `${API_URL}/login`
// export const REGISTER_URL = `${API_URL}/register`
// export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// // Server should return AuthModel
// export function login(email: string, password: string) {
//   return {
//     data: {
//       api_token: "string",
//       refreshToken: "string"
//     }
//   }
// }

// // Server should return AuthModel
// export function register(
//   email: string,
//   firstname: string,
//   lastname: string,
//   password: string,
//   password_confirmation: string
// ) {
//     return {
//         data: {
//           id: 132,
//           username: "string",
//           password: "string",
//           email: "assistouch@admin.com",
//           first_name: "Assistouch",
//           last_name: "Demo"
//         }
//     }
// }

// // Server should return object => { result: boolean } (Is Email in DB)
// /*export function requestPassword(email: string) {
//     return {
//       result: true
//     }
// }*/

// // Server should return object => { result: boolean } (Is Email in DB)
// export function requestPassword(email: string) {
//   return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
//     email,
//   })
// }

// export function getUserByToken(token: string) {
//   return {
//     data: {
//       id: 132,
//       username: "string",
//       password: "string",
//       email: "assistouch@admin.com",
//       first_name: "Assistouch",
//       last_name: "Demo"
//     }
//   }
// }

import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../base/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../app/pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../app/pages/MenuTestPage'
import {getCSSVariableValue} from '../base/assets/ts/_utils'
import {WithChildren} from '../base/helpers'
import BuilderPageWrapper from '../app/pages/layout-builder/BuilderPageWrapper'
import { UserList } from '../app/pages/user/UserList'
import { ProfileOverview } from '../app/pages/profile/ProfileOverview'
import { NewDemandList } from '../app/pages/demand/NewDemandList'
import { ComponentTestPage } from '../app/pages/test/ComponentTestPage'
import EditDemand from '../app/pages/demand/EditDemand'
import { ActiveDemandList } from '../app/pages/demand/ActiveDemandList'
import { HistoryDemandList } from '../app/pages/demand/HistoryDemandList'
import { CreatePayment } from '../app/pages/payments/CreatePayment'
import { HistoryPaymentList } from '../app/pages/payments/HistoryPaymentList'
import { ActivePaymentList } from '../app/pages/payments/ActivePaymentList'
import { ChatTestPage } from '../app/pages/chat/ChatTestPage'
import { AssignedDemandList } from '../app/pages/demand/AssignedDemandList'
import { ActionDemand } from '../app/pages/demand/ActionDemand'
import { UploadTestPage } from '../app/pages/test/UploadTestPage'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../app/modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../app/modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../app/modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../app/modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../app/modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../app/modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/apps/chat/group-chat' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path="/user-list" element={<UserList />} />
        <Route path="/assigned-demand-list" element={<AssignedDemandList />} />
        <Route path="/new-demand-list" element={<NewDemandList />} />
        <Route path="/active-demand-list" element={<ActiveDemandList />} />
        <Route path="/history-demand-list" element={<HistoryDemandList />} />
        <Route path="/create-payment" element={<CreatePayment />} />
        <Route path="/active-payment-list" element={<ActivePaymentList />} />
        <Route path="/history-payment-list" element={<HistoryPaymentList />} />
        <Route path="/action-demand/:id" element={<ActionDemand />} />
        <Route path="/edit-demand/:id" element={<EditDemand />} />
        <Route path="/profile" element={<ProfileOverview />} />
        <Route path="/test" element={<ComponentTestPage />} />
        <Route path="/test-upload" element={<UploadTestPage />} />
        <Route path="/message-test" element={<ChatTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}

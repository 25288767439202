function CSwitch({ options }: any) {
    
  const text = options.text;
  const isDisabled = options.disabled;
  const isChecked = options.checked;
  const height = options.height;
  const width = options.width;

  return (
    <>
      <div className="mb-10">
        <div className="form-check form-switch form-check-custom form-check-solid">
          <input
            id="flexSwitchDefault"
            className={`form-check-input h-${height}px w-${width}px`}
            type="checkbox"
            value=""
            disabled={isDisabled}
            checked={isChecked}
          />
          <label className="form-check-label" htmlFor="flexSwitchDefault">
            {text}
          </label>
        </div>
      </div>
    </>
  );
}

export default CSwitch;

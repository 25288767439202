import { useState } from "react";
import { UserService } from "../../../services/user";
import { DemandModel } from "../../../models/DemandModel";

export function UploadTestPage() {
  const [serviceError, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { uploadFileTest, uploadMultipleFileTest, downloadFileTest } = UserService();
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState<File[]>([]);

  const onFileChange = (event) => {
    console.log('files1: ', event.target.files);
    console.log("---------------");
    console.log(event.target.files[0]);
    setFile(event.target.files[0]);
  };

  const onFilesChange = (event) => {
    console.log('files2: ', event.target.files);
    console.log("---------------");
    const selectedFiles = event.target.files;
    if (selectedFiles) {
        setFiles(Array.from(selectedFiles));
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    console.log('Button|files: ', files);
    console.log('Button|files.length: ', files.length);

    if (!file) {

      if(!files || files.length <= 0) {
        alert("Lütfen bir dosya seçin");
        return;
      }
    }

    const formData = new FormData();
    
    if(file) {
      formData.append("demand-file", file);
      console.log('reqFile: ', formData.get('demand-file')); 
      submitSingleFile(formData);
    } else if(files && files.length > 0) {
      files.forEach(file => {
        formData.append('demand-files', file);
      });
      console.log('reqFiles: ', formData.getAll('demand-files')); 

      
      submitMultipleFile(formData);
    }
    

  };

  const submitSingleFile = async (formData: FormData) => {
    try {
      setLoading(true);
      const response = await downloadFileTest(formData);
      console.log("Dosya başarıyla yüklendi:", response.data);
    } catch (error) {
      console.error("Dosya yüklenirken hata oluştu:", error);
    } finally {
      setLoading(false);
    }
  };

  const submitMultipleFile = async (formData: FormData) => {
    try {
      setLoading(true);
      const demand = {
        id: 1,
        status: 101,
        user_id: 12,
        assigned_personelid: 1,
        description: "Test",
        note: "Note",
        created_date: new Date()
      };

      formData.append('demand', JSON.stringify(demand));
      const response = await uploadMultipleFileTest(formData);
      console.log("Dosyalar başarıyla yüklendi:", response.data);
    } catch (error) {
      console.error("Dosyalar yüklenirken hata oluştu:", error);
    } finally {
      setLoading(false);
    }
  };

  const indirDosya = async () => {
    try {
      const tmpData = { id: 77 };
      
      const response = await downloadFileTest({"demandDetail": tmpData });
      console.log('response: ', response);

      if (response) {

      }
    } catch (e: any) {
      console.error("Error fetching data:", JSON.stringify(e));
    }
  }


  return (
    <>
     <button onClick={indirDosya} className='btn btn-primary'>
      Dosya İndir
     </button>
      <p>Dosya Yükle</p>

      <form
        onSubmit={onSubmit}
        method="post"
        encType="multipart/form-data"
        className="form"
      >
        <hr />
        <p>Tekli Dosya</p>
        <input type="file" name="demand-file" onChange={onFileChange}/>
        <hr />
        <p>Çoklu Dosya</p>
        <input type="file" multiple name="demand-files" onChange={onFilesChange}/>

        <button type="submit" className="btn btn-primary" disabled={loading}>
          {!loading && "Ekle"}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Lütfen Bekleyiniz...{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </form>

      <div>
        {serviceError && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">Hata: {serviceError}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

import { isNullOrEmpty } from "../../base/helpers";
import { TokenModel } from "../../models";
import useProxyManager from "../core/useProxyManager"
import { setUserInfo } from "../../stores/user";

export const AuthService = () => {

    const { httpPostLogin } = useProxyManager();
    const _basePath = "callcenter/auth/";

    const loginService = async (email: any, password: any, dispatch: any): Promise<TokenModel> => {
        const response = await httpPostLogin(_basePath + "login", {email, password});
        
        if(response === undefined || response === null || response.status !== 200) {
            throw new Error('Unauthorizated')
        }
        
        const token = response.auth;
        const userInfo = response.user;

        if(isNullOrEmpty(token) || isNullOrEmpty(userInfo)) {
            throw new Error('Unauthorizated Missing Token')
        }

        dispatch(setUserInfo(userInfo))

        setSecureCookie("refreshToken", token.refresh_token, 15);

        return {
            access_token: token.access_token,
            refresh_token: token.refresh_token
        };
    };

    return {
        loginService
    };

    /*function setCookie(name: any, value: any, days: any) {
        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }*/

    function setSecureCookie(name: any, value: any, minutes: any) {
        let expires = "";
        if (minutes) {
            const date = new Date();
            date.setTime(date.getTime() + (minutes * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/; Secure";
    }


};

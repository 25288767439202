import React, { useEffect, useState } from "react";
import _ from 'lodash';

function CTimeline({ props }: any) {

    const [items, setItems] = useState<any[]>([]);
    
    function getTimeString(date: Date) {
        if(date !== undefined && date !== null) {
            return date.toLocaleTimeString('tr-TR', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
              });
        } else {
            return "";
        }
        
      }

      // Sıralama fonksiyonu
    function sortData(data, orderBy, order) {
        return data.sort((a, b) => {
            if (order === 'asc') {
                return a[orderBy] > b[orderBy] ? 1 : -1;
            } else {
                return a[orderBy] < b[orderBy] ? 1 : -1;
            }
        });
    }

      useEffect(() => {

        //todo
        if(props && props.items && props.items.length > 0) {
            console.log('items1', items);
            const orderedItems = _.orderBy(props.items, ['time'], ['asc']);
            setItems(orderedItems);
            console.log('items2', items);
        }
        

      }, []);

    return (
        
        <>
        {console.log('props: ', props)}
        <div className='card-body pt-5'>
            {/* begin::Timeline */}
            <div className='timeline-label'>
                {items && items.length > 0 &&
                    items.map((item: any, index: any) => {
                        return (
                            <React.Fragment key={`timeline-item-${index}`}>
                                {/* begin::Item */}
                                <div className='timeline-item'>
                                    {/* begin::Label */}
                                    <div className='timeline-label fw-bold text-gray-800 fs-6'>{(item && item.time) ? getTimeString(new Date(item.time)) : ''}</div>
                                    {/* end::Label */}
                                    {/* begin::Badge */}
                                    <div className='timeline-badge'>
                                        <i className={`fa fa-genderless text-${item?.color ?? 'success'} fs-1`}></i>
                                    </div>
                                    {/* end::Badge */}
                                    {/* begin::Content */}
                                    <div className='timeline-content d-flex'>
                                        <span className={`${item?.classname ?? 'timeline-content fw-mormal text-muted ps-3'}`}>{item?.text}</span>
                                    </div>
                                    {/* end::Content */}
                                </div>
                                {/* end::Item */}
                            </React.Fragment>
                        );
                    })
                }
            </div>
            {/* end::Timeline */}
        </div>
        </>
    )
}

export default CTimeline;
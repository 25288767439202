import { createSlice } from "@reduxjs/toolkit";

/*type TestState = any[];

const initialState: TestState = [];*/

const initialState: any = {
    userInfo: []
}

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getUser: (state, action) => {
            const userInfo = {
                type: "",
                data: action.payload
            }

            state.push(userInfo)

            /*state.info = [
                action.payload,
            ]*/
        },
        setUserInfo: (state, action) => {
            console.log('state: ',state.userInfo);
            state.userInfo = [
                action.payload,
                ...state.userInfo
            ]
        }
    }
})


export const { getUser, setUserInfo } = user.actions
export default user.reducer

function CButton({ options }: any) {


  const { color, text, redirectUrl, onClickFunc, icon: IconComponent } = options;

  return (
    <>
      <button onClick={onClickFunc} className={`btn btn-${color}`}>
        {IconComponent && <IconComponent className="mr-2" />} {/* İkonu metinden önce göster */}
        {text}
      </button>
    </>
  )
}

export default CButton;
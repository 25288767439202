import { useState, useEffect } from "react";
import CTable from "../../../components/CTable/CTable";
import { TableColumn } from "../../../components/CTable/model/TableColumn";
import { DemandModel } from "../../../models";
import { DemandService } from "../../../services/demand";
import { Icons } from "../../../shared/constant/icon/Icons";

export function UserList() {
  const [data, setData] = useState<DemandModel[]>();
  const { getAllDemand } = DemandService();

  const columns: TableColumn<DemandModel>[] = [
    { label: "Talep Numarası", key: "id", width: 110 },
    { label: "Durumu", key: "status", width: 110 },
    { label: "Kullanıcı", key: "user_id", width: 120 },
    { label: "Atanan Personel", key: "assigned_personelid", width: 130 },
    { label: "Açıklama", key: "description", width: 120 },
    { label: "Talep Notu", key: "note", width: 120 },
    { label: "Oluşturulma Zamanı", key: "created_date", width: 120 },
    { label: "Aksiyon", key: undefined, width: 130 },
  ];

  const tableOptions = {
    header: "Kullanıcılar",
    description: "Tüm Kullanıcı Listesi",
    columns: columns,
    data: data,
    icon: Icons.ArrowRight,
    navigateURL: "edit-demand",
    navigateKey: "id",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllDemand(1, 10);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <CTable columns={columns} data={data} options={tableOptions}></CTable>
    </>
  );
}

//import { Provider } from "agora-chat-uikit";
//import 'agora-chat-uikit/style.css';
//import ChatApp from "../../../base/integration/chat/ChatApp";


export function ChatTestPage() {

  /*const appKey = "711188632#1379843"; // your appKey
  const user = "123456"; // your user ID
  const agoraToken = "007eJxTYJg6U+b+0Y29CsoLbQ/HZGjpFMx9w7h3BoewuW3Sz/kP21IUGMxNLMwNLdOMjZMMTUwMjYwtLM2NTE1TEg3Nk4xMEo1TJM5fTGsIZGRwPPyalZGBlYGRgYkBxGdgAAAykhyf"; */
  
  return (
    <>
      {/* <Provider
        initConfig={{
          appKey,
        }}
      >
        <ChatApp />
      </Provider> */}
    </>
  );
}

import { UserModel } from "../../models/UserModel";
import useProxyManager from "../core/useProxyManager"

export const UserService = () => {

    const { httpGet, httpPostFile, httpPostFormData } = useProxyManager();
    const _basePath = "callcenter/user";


    const getUserInfo = async (): Promise<UserModel> => {
        const data = await httpGet(_basePath + "/profile");

        if(data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data;
    };

    const uploadFileTest = async (reqData: any): Promise<any> => {
        const data = await httpPostFormData("tests/upload-file", reqData);

        if(data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data;
    };

    const uploadMultipleFileTest = async (reqData: any): Promise<any> => {
        const data = await httpPostFormData("tests/upload-multiple-file", reqData);

        if(data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data;
    };

    const downloadFileTest = async (reqData: any): Promise<any> => {
        const data = await httpPostFile("file/get", reqData);

        if(data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data;
    };

    return {
        getUserInfo,
        uploadFileTest,
        uploadMultipleFileTest,
        downloadFileTest
    };
};


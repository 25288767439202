import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
// Apps
import {MetronicI18nProvider} from './base/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './base/assets/sass/style.scss'
import './base/assets/sass/plugins.scss'
import './base/assets/sass/style.react.scss'
import {AppRoutes} from './routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
//import AC, { AgoraChat } from 'agora-chat'

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

//agora-chat
// Replaces <Your app key> with your app key.
/*const appKey = "<Your app key>";
// Initializes the Web client.
const conn = new AC.connection({
	appKey: appKey,
});
// Adds the event handler.
conn.addEventHandler("connection&message", {
	// Occurs when the app is connected to Agora Chat.
	onConnected: () => {
		document
			.getElementById("log")
			.appendChild(document.createElement("div"))
			.append("Connect success !");
	},
	// Occurs when the app is disconnected from Agora Chat.
	onDisconnected: () => {
		document
			.getElementById("log")
			.appendChild(document.createElement("div"))
			.append("Logout success !");
	},
	// Occurs when a text message is received.
	onTextMessage: (message) => {
		console.log(message);
		document
			.getElementById("log")
			.appendChild(document.createElement("div"))
			.append("Message from: " + message.from + " Message: " + message.msg);
	},
	// Occurs when the token is about to expire.
	onTokenWillExpire: (params) => {
		document
			.getElementById("log")
			.appendChild(document.createElement("div"))
			.append("Token is about to expire");
	},
	// Occurs when the token has expired. 
	onTokenExpired: (params) => {
		document
			.getElementById("log")
			.appendChild(document.createElement("div"))
			.append("The token has expired");
	},
	onError: (error) => {
		console.log("on error", error);
	},
});*/

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </MetronicI18nProvider>
    </QueryClientProvider>
  )
}

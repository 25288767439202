import { useEffect, useState } from "react";
import { TableColumn } from "../../../components/CTable/model/TableColumn";
import { DemandService } from "../../../services/demand";
//import { TableData } from "../../../components/CTable/model/TableData";
import CTable from "../../../components/CTable/CTable";
import { DemandModel } from "../../../models";
import { Icons } from "../../../shared/constant/icon/Icons";
import { PaginationProps } from "../../../components/CTable/model/PaginationProps";
import { DemandStatusCodes } from "../../../shared/enum/DemandStatusCodes";
import { TableColors } from "../../../shared/constant/color/TableColors";

export function NewDemandList() {
  const [data, setData] = useState<DemandModel[]>();
  const [pagination, setPagination] = useState<PaginationProps>();
  const { getAllNewDemands } = DemandService();

  //let tableData: DemandModel[] = [];

  const columns: TableColumn<DemandModel>[] = [
    { label: "Talep Numarası", key: "id", width: 110 },
    { label: "Talep Tipi", key: "note", width: 100, color: TableColors.Danger, bold: true },
    {
      label: "Durumu",
      key: "status_text",
      width: 110,
      bold: true,
      colorkey: "color",
    },
    { label: "Kullanıcı", key: "user_id", width: 120 },
    { label: "Açıklama", key: "description", width: 130 },
    { label: "Oluşturulma Zamanı", key: "created_date_text", width: 100 },
  ];

  const tableOptions = {
    header: "Yeni Talepler",
    description: "Yeni gelen talep listesi",
    columns: columns,
    data: data,
    icon: Icons.ArrowRight,
    navigateURL: "edit-demand",
    navigateKey: "id",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllNewDemands(1, 10);

        response.data.map((item: any) => {
          const dateObject = new Date(item["created_date"]);
          item["created_date_text"] = dateObject.toLocaleString();
          let color;
          switch (item.status) {
            case DemandStatusCodes.Taslak:
            case DemandStatusCodes.CallcenterOnayinda:
            case DemandStatusCodes.KullaniciyaIadeEdildi:
            case DemandStatusCodes.KullaniciIadeEtti:
              color = TableColors.Warning;
              break;
            case DemandStatusCodes.OdemeBekleniyor:
            case DemandStatusCodes.KullaniciOnayinda:
              color = TableColors.Info;
              break;
            case DemandStatusCodes.CallcenterReddetti:
            case DemandStatusCodes.KullaniciReddetti:
            case DemandStatusCodes.ZamanAsimi:
              color = TableColors.Danger;
              break;
            case DemandStatusCodes.Isleniyor:
              color = TableColors.Primary;
              break;
            case DemandStatusCodes.Tamamlandi:
              color = color = TableColors.Success;
              break;
            default:
              color = TableColors.Warning;
              break;
          }
          item.color = color;
        });
        setData(response.data);

        const paginationData: PaginationProps = {
          currentPage: (response.currentPage as number) || 1,
          totalPages: response.totalPages || 1,
        };

        setPagination(paginationData);
      } catch (e: any) {
        console.error("Error fetching data:", JSON.stringify(e));
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <CTable
        columns={columns}
        data={data}
        options={tableOptions}
        pagination={pagination}
      ></CTable>
    </>
  );
}

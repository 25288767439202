//import { DemandModel } from "../../models/DemandModel";
import { isNullOrEmpty } from "../../base/helpers";
import { ServiceResponse } from "../../models";
import useProxyManager from "../core/useProxyManager"

export const DemandService = () => {

    const { httpGet, httpPost } = useProxyManager();

    const _basePath = "callcenter/demand/";

    //todo: DemandModel
    const getAllDemand = async (page: number, pageSize: number): Promise<ServiceResponse> => {
        const data = await httpGet(`${_basePath}all?page?=${page}&pageSize?=${pageSize}`);

        //TODO: helper a alınacak
        if(data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data;
    };

    const getAllAssignedDemands = async (page: number, pageSize: number): Promise<ServiceResponse> => {
        const data = await httpGet(`${_basePath}all-assigned?page?=${page}&pageSize?=${pageSize}`);

        //TODO: helper a alınacak
        if(data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data;
    };

    const getAllNewDemands = async (page: number, pageSize: number): Promise<ServiceResponse> => {
        const data = await httpGet(`${_basePath}all-new?page?=${page}&pageSize?=${pageSize}`);

        //TODO: helper a alınacak
        if(data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data;
    };

    const getAllProcessedDemands = async (page: number, pageSize: number): Promise<ServiceResponse> => {
        const data = await httpGet(`${_basePath}all-processed?page?=${page}&pageSize?=${pageSize}`);

        //TODO: helper a alınacak
        if(data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data;
    };

    const getAllFinishedDemands = async (page: number, pageSize: number): Promise<ServiceResponse> => {
        const data = await httpGet(`${_basePath}all-history?page?=${page}&pageSize?=${pageSize}`);

        //TODO: helper a alınacak
        if(data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data;
    };

    const getDemandById = async (id: any) : Promise<ServiceResponse> => {
        const data = await httpGet(`${_basePath}${id}`);

        //TODO: helper a alınacak
        if(data === undefined || data === null) {
            throw new Error('Not Found')
        }

        return data;
    };

    const updateDemandAssing = async (reqData: any) : Promise<ServiceResponse> => {

        const response = await httpPost(`${_basePath}update-assing`, reqData);

        if(isNullOrEmpty(response)) {
            throw new Error('Dont Update')
        }

        return response;
    };

    const getDemandAllDetailById = async (id: any) : Promise<ServiceResponse> => {
        const response = await httpPost(`${_basePath}all-detail`, {id});

        if(isNullOrEmpty(response)) {
            throw new Error('Not Found')
        }

        return response;
    };


    return {
        getAllDemand,
        getAllNewDemands,
        getAllAssignedDemands,
        getAllProcessedDemands,
        getAllFinishedDemands,
        getDemandById,
        updateDemandAssing,
        getDemandAllDetailById
    };
};


import { useState } from "react";
import CTabItem from "../CTabItem/CTabItem";

function CTab({ props, children }: any) {
  const [activeIndex, setActiveIndex] = useState(0);

  const columns = props.columns;
  const options = props.options;

  // children'dan CTabItem'ları filtreleyin
  const tabItems = children.filter((child: any) => child.type === CTabItem);

  return (
    <>
      <div className="card card-custom shadow">
        <div className="card-header card-header-stretch">
          <h3 className="card-title">{options.title}</h3>
          <div className="card-toolbar">
            <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
              {columns &&
                columns.length > 0 &&
                columns.map((item: any, index: any) => {
                  return (
                    <li className="nav-item" key={"tab" + index}>
                      <a
                        className={`nav-link ${
                          activeIndex === index ? "active" : ""
                        }`}
                        data-bs-toggle="tab"
                        href={`${"#tab_" + index}`}
                        onClick={() => setActiveIndex(index)}
                      >
                        {item.label}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div className="card-body">
          <div className="tab-content" id="myTabContent">
            {columns &&
              columns.length > 0 &&
              columns.map((item: any, index: any) => {
                return (
                  <div
                    className={`tab-pane fade ${
                      activeIndex === index ? "show active" : ""
                    }`}
                    id={`${"kt_tab_pane_" + index}`}
                    role="tabpanel"
                    key={"tab" + index}
                  >
                    {/* Aktif olan tab için ilgili CTabItem'ı render edin */}
                    {tabItems[index]}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default CTab;

//import React from "react";
import { Link } from "react-router-dom";
import { isNullOrEmpty, KTSVG, toAbsoluteUrl } from "../../../base/helpers";
import { Dropdown1 } from "../../../base/partials";
import { useSelector } from "react-redux";
import { StaffLanguages } from "../../../shared/enum/Staff/StaffLanguages";

const ProfileOverview = () => {
  const tmpUser = useSelector((state: any) => state.user.userInfo);
  const userInfo = tmpUser[0];
  let role = isNullOrEmpty(userInfo) ? '' : userInfo.role_id === 1 ? 'Çağrı Merkezi Personeli' : 'Diğer';

  return (
    <>
      <div className="card mb-5 mb-xl-10">
        <div className="card-body pt-9 pb-0">
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            <div className="me-7 mb-4">
              <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <img
                  src={toAbsoluteUrl("/media/avatars/300-1.jpg")}
                  alt="Metornic"
                />
                <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
              </div>
            </div>

            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <a
                      href="#"
                      className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                    >
                      {userInfo?.name} {userInfo?.surname}
                    </a>
                    <a href="#">
                      <KTSVG
                        path="/media/icons/duotune/general/gen026.svg"
                        className="svg-icon-1 svg-icon-primary"
                      />
                    </a>
                  </div>

                  <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                    <a
                      href="#"
                      className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                    >
                      <KTSVG
                        path="/media/icons/duotune/communication/com006.svg"
                        className="svg-icon-4 me-1"
                      />
                       {role}
                    </a>
                    <a
                      href="#"
                      className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                    >
                      <KTSVG
                        path="/media/icons/duotune/communication/com011.svg"
                        className="svg-icon-4 me-1"
                      />
                      {userInfo?.email}
                    </a>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-wrap flex-stack">
                <div className="d-flex flex-column flex-grow-1 pe-8">
                  <div className="d-flex flex-wrap"></div>
                </div>
              </div>

              <div className="card-body py-3">
                <div className="row mb-6">
                  <label
                    htmlFor="name"
                    className="col-lg-4 col-form-label fw-bold fs-6"
                  >
                    Telefon Numarası:
                  </label>
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-lg-6 fv-row">
                        <input
                          type="text"
                          id="name"
                          value={userInfo?.phone}
                          disabled
                          className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body py-3">
                <div className="row mb-6">
                  <label
                    htmlFor="name"
                    className="col-lg-4 col-form-label fw-bold fs-6"
                  >
                    Tercih Edilen Dil:
                  </label>
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-lg-6 fv-row">
                        <select
                          id="language"
                          value={userInfo?.language}
                          disabled
                          className="form-select form-select-solid form-select-lg mb-3 mb-lg-0"
                        >
                          <option value={StaffLanguages.None} label="Seçiniz" />
                          <option value={StaffLanguages.Turkish}>Türkçe</option>
                          <option value={StaffLanguages.English}>
                            İngilizce
                          </option>
                        </select>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button type="submit" disabled className="btn btn-primary">
                  Düzenle
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ProfileOverview };

/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
//import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Araçlar</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Talepler'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem to='/assigned-demand-list' title='Görevlerim' hasBullet={true} />
          <AsideMenuItem to='/new-demand-list' title='Yeni Gelen Talepler' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Kullanıcılar'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'>
          <AsideMenuItem
            to='/apps/user-management/users'
            icon='/media/icons/duotune/general/gen051.svg'
            title='Kullanıcı Yönetimi(Test silinecek)'
            fontIcon='bi-layers'
          />
           <AsideMenuItem
            to='/user-list'
            icon='/media/icons/duotune/general/gen051.svg'
            title='Kullanıcı Yönetimi'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/test-upload'
            icon='/media/icons/duotune/general/gen051.svg'
            title='Dosya Yükle'
            fontIcon='bi-layers'
          />
      </AsideMenuItemWithSub>
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Uygulamalar</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Mesajlar'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Müşteri Mesajlar' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Kurum Mesajlar' hasBullet={true} />
        <AsideMenuItem to='/message-test' title='Mesaj Test Sayfası' hasBullet={true} />
      </AsideMenuItemWithSub> */}
       <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Ayarlar</span>
        </div>
        <AsideMenuItemWithSub
        to='/apps/chat'
        title='Profil Ayarları'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/profile' title='Profilim' hasBullet={true} />
      </AsideMenuItemWithSub>
      </div>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}

import { useProxyManager } from "../core";

export const FileService = () => {

    const { httpPostFile } = useProxyManager();

    const _basePath = "callcenter/file/";

    const downloadFile = async (reqData: any): Promise<any> => {

        const response = await httpPostFile(_basePath + "get", reqData);

        return response;
    };

    return {
        downloadFile
    };
}